import React from "react"
import { Link } from "gatsby"

export const DemandeOrga: React.FC = () => (
  <div>
    <h3>2025/2026 - Candidature à l'organisation de CR ou DRE.</h3>

    <p>
      <ul>
        <li>
          Les dates définitives vous seront communiquées dès la publication des dates des D1 et D2 par la FFTA.
        </li>

        <li>
          L'attribution se fera par la Commission Sportive avant la clôture du calendrier fédéral salle et extérieur.
        </li>

        <li>
          Les candidatures devront respecter le règlement intérieur du CRTA et le {" "}
          <b>
            <Link to="/vie-sportive/documents/">cahier des charges correspondant à la compétition demandée</Link>
          </b>
          .
        </li>
      </ul>
    </p>
  </div>
)
