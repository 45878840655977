import React from "react"
import { Link } from "gatsby"

export const ChampionnatsSalle: React.FC = () => (
  <div>
    <h3>Mode de sélection</h3>

    <p>
      <ul>
        <li>
          La période des classements régionaux débute le 1<sup>er</sup> septembre et s’arrête le 31 août suivant. Les conditions de
          participation aux Championnats Régionaux sont les mêmes que pour un Championnat de France.{" "}
        </li>
        <li>
          Les classements régionaux sont établis selon le même règlement que pour les classements nationaux.
        </li>
        <li>
          Pour pouvoir être qualifié, un archer doit avoir participé à au moins 3 compétitions en salle.
        </li>
        <li>
          Les classements régionaux et l’arrêté sélectif pour les Championnats Régionaux sont établis sur{" "}
          <b>la moyenne des 3 meilleurs scores</b>. En cas d’égalité, la même règle que pour un Championnat de France sera appliquée.
        </li>
        <li>
          L’arrêté sélectif pour les Championnats Régionaux Salle 2024 se fera <b>le 28 janvier 2024</b>.
        </li>

        <li>
          La situation du classement et des qualifiés est disponible{" "}
          <b>
            <a href="https://cloud.tiralarc-grand-est.fr/s/NCHpyy2XGsZz8fG" target="_blank" rel="noopener noreferrer">
              ici
            </a>
          </b>
          . Elle est mise à jour régulièrement jusqu'à l'arrêté final du 28 janvier.
        </li>
      </ul>
    </p>

    <h3>Inscription</h3>

    <p>
      <ul>
        <li>
          Chaque archer désirant participer aux Championnats Régionaux doit faire{" "}
          <b>une demande de pré-inscription spontanée via le formulaire en ligne disponible en haut de page</b>. Les archers préinscrits
          apparaîtront sur les classements régionaux hebdomadaires.
        </li>
        <li>
          Dans la colonne "pré-inscrit", une coche verte indique un archer qualifié, une coche rouge indique un archer hors quota ou n'ayant pas participé à au moins 3 compétitions.
        </li>
        <li>
          Les archers qualifiés auront <b>jusqu'au 1<sup>er</sup> février</b> pour confirmer leur inscription et régler la mise via HelloAsso (v. lien en haut de cette page).&nbsp;
          Compte tenu du délai restreint entre la fin de la période sélective et la date limite de confirmation d'inscription,&nbsp;
          il est demandé aux archers de confirmer <b>le plus tôt possible</b>. Le paiement valant confirmation sera possible <b>dès le 22 janvier</b>. <br />
          <b>Ceux qui auront payé mais qui ne seront pas retenus seront remboursés.</b>
        </li>
      </ul>
    </p>

    <h3>Déroulement des tirs</h3>

    <p>
      <ul>
        <li>Les phases de qualifications (2 séries de 10 volées de 3 flèches) se dérouleront le matin.</li>
        <li>Pour les arcs classiques et les arcs à poulies, elles se feront sur des blasons
          triples verticaux et sur blason unique pour les arcs nus correspondant à la catégorie. </li>
        <li>Après les qualifications et à l'issue de la pause méridienne, les <b>phases finales individuelles se tirant par matches</b>{" "}
          seront organisées selon le même principe que pour un championnat de France de tir en salle.{" "}
        </li>
        <li><b>Toutes les phases finales se feront sur des blasons triples verticaux.</b></li>
        <li>
          À noter que <b>tous les archers présents seront retenus pour les phases finales.</b> Il n’y aura donc pas d’éliminations directes
          à l’issue des phases de qualifications.
        </li>
      </ul>
    </p>
  </div>
)
