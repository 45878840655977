import { Section } from "./utils"

const sections: Section[] = [
  // {
  //   link: "https://livre-reglements.tiralarc-grand-est.fr/",
  //   name: "Livre des règlements FFTA",
  //   index: false,
  // },
  {
    link: "/vie-sportive/arbitrage/",
    name: "Arbitrage",
    index: false,
  },
  {
    link: "/vie-sportive/calendrier/",
    name: "Calendrier régional",
    index: true,
  },
  {
    link: "/vie-sportive/classement/",
    name: "Classement régional",
    index: false,
  },
  // {
  //   link: "/vie-sportive/performances/",
  //   name: "Meilleures performances",
  //   index: false,
  // },
  {
    link: "/vie-sportive/championnats-regionaux/",
    name: "Championnats régionaux",
    index: false,
  },
  // {
  //   link: "/vie-sportive/weekend-horizon/",
  //   name: "Weekend Horizon",
  //   index: false,
  // },
  {
    link: "/vie-sportive/dre/",
    name: "DRE",
    index: false,
  },
  // {
  //   link: "/vie-sportive/aides/",
  //   name: "Aides régionales",
  //   index: false,
  // },
  {
    link: "/vie-sportive/documents/",
    name: "Documents",
    index: false,
  },
]

export default sections
